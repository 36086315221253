import { language } from 'shared/utils';

export const SERVICE_ORDER_STATUS_UNCONFIRMED = 'PENDING';
export const SERVICE_ORDER_STATUS_WAITING_CANCEL = 'WAITING_FOR_CANCELLED';
export const SERVICE_ORDER_STATUS_WAITING_FINISH = 'WAITING_FOR_COMPLETED';
export const SERVICE_ORDER_STATUS_CONFIRMED = 'APPROVED';
export const SERVICE_ORDER_STATUS_FINISHED = 'COMPLETED';
export const SERVICE_ORDER_STATUS_CANCELED = 'CANCELLED';
export const SERVICE_ORDER_STATUS_ALL = 'ALL';

export const SERVICE_ORDER_STATUS_ARRAY = [
  {
    key: SERVICE_ORDER_STATUS_UNCONFIRMED,
    label: language.unconfirmed,
  },
  {
    key: SERVICE_ORDER_STATUS_WAITING_CANCEL,
    label: language.waiting_cancel,
  },
  {
    key: SERVICE_ORDER_STATUS_WAITING_FINISH,
    label: language.waiting_finish,
  },
  {
    key: SERVICE_ORDER_STATUS_CONFIRMED,
    label: language.confirmed,
  },
  {
    key: SERVICE_ORDER_STATUS_FINISHED,
    label: language.finished,
  },
  {
    key: SERVICE_ORDER_STATUS_CANCELED,
    label: language.canceled,
  },
  {
    key: SERVICE_ORDER_STATUS_ALL,
    label: language.all,
  },
];

export const SERVICE_ORDER_STATUS_MAPPING = {
  [SERVICE_ORDER_STATUS_UNCONFIRMED]: language.unconfirmed,
  [SERVICE_ORDER_STATUS_WAITING_CANCEL]: language.waiting_cancel,
  [SERVICE_ORDER_STATUS_WAITING_FINISH]: language.waiting_finish,
  [SERVICE_ORDER_STATUS_CONFIRMED]: language.confirmed,
  [SERVICE_ORDER_STATUS_FINISHED]: language.finished,
  [SERVICE_ORDER_STATUS_CANCELED]: language.canceled,
  [SERVICE_ORDER_STATUS_ALL]: language.all,
};

export const SERVICE_ORDER_ACCEPT = 'ACCEPT';
export const SERVICE_ORDER_REJECT = 'REJECT';

export const SERVICE_ORDER_ACTIONS = [
  {
    key: SERVICE_ORDER_ACCEPT,
    label: language.accept,
    icon: 'check',
  },
  {
    key: SERVICE_ORDER_REJECT,
    label: language.reject,
    icon: 'times',
  },
];

export const SERVICE_BILL_SURCHARGE = 'UTILITY_SURCHARGE';
export const SERVICE_BILL_UTILITY = 'UTILITY';
