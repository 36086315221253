import { createAction } from 'shared/utils';

export const SERVICE_ORDER_DETAIL = {
  APPROVE: createAction('SERVICE_ORDER_DETAIL_APPROVE'),
  REJECT: createAction('SERVICE_ORDER_DETAIL_REJECT'),
  CANCEL: createAction('SERVICE_ORDER_DETAIL_CANCEL'),
  PAYMENT: createAction('SERVICE_ORDER_DETAIL_PAYMENT'),
  REFUND: createAction('SERVICE_ORDER_DETAIL_REFUND'),
  REFUND_DEPOSIT: createAction('SERVICE_ORDER_DETAIL_REFUND_DEPOSIT'),
  GET: createAction('SERVICE_ORDER_DETAIL_GET'),
  PREVIEW_RECEIPT: createAction('SERVICE_ORDER_DETAIL_PREVIEW_RECEIPT'),
  PREVIEW_PAYSLIP: createAction('SERVICE_ORDER_DETAIL_PREVIEW_PAYSLIP'),
};
