import { combineReducers } from 'redux';
import applicationReducer, { initState as initStateApplication } from 'containers/Application/ducks';
import amenitiesOrderReducer, { initState as initStateAmenitiesOrder } from 'containers/AmenitiesOrder/ducks';
import amenitiesOrderDetailReducer, {
  initState as initStateAmenitiesOrderDetail,
} from 'containers/AmenitiesOrder/AmenitiesDetail/ducks';
import amenitiesOrderConfirmChangeStatusReducer, {
  initState as initStateAmenitiesOrderConfirmChangeStatus,
} from 'containers/AmenitiesOrder/AmenitiesOrderConfirmChangeStatus/ducks';

export const initState = {
  application: initStateApplication,
  amenitiesOrder: initStateAmenitiesOrder,
  amenitiesOrderDetail: initStateAmenitiesOrderDetail,
  amenitiesOrderConfirmChangeStatus: initStateAmenitiesOrderConfirmChangeStatus,
};

const reducers = combineReducers({
  application: applicationReducer,
  amenitiesOrder: amenitiesOrderReducer,
  amenitiesOrderDetail: amenitiesOrderDetailReducer,
  amenitiesOrderConfirmChangeStatus: amenitiesOrderConfirmChangeStatusReducer,
});

export default reducers;
