/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { language } from 'shared/utils';
import PermissionDenied from '../PermissionDenied';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <PermissionDenied
          code="500"
          description={
            language.the_page_you_are_looking_for_might_have_been_removed_had_its_name_changed_or_is_temporarily_unavailable
          }
        />
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  children: <div />,
};

export default ErrorBoundary;
