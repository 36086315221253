import { language } from 'shared/utils';
import { Modal } from 'antd';
import { PAGINATION_LIMIT } from 'shared/constants';

const useConfirmBox = ({ questionText, loading, isAll, totalItem, delayTime = 6, onOk, onCancel }) => {
  const question =
    typeof questionText === 'object'
      ? questionText
      : questionText.replace('{item}', isAll ? language.all.toLowerCase() : totalItem);
  if (delayTime === 0 || (!isAll && totalItem < PAGINATION_LIMIT)) {
    Modal.confirm({
      okText: language.confirm,
      okButtonProps: {
        disabled: loading,
      },
      cancelText: language.cancel,
      title: language.confirm,
      content: question,
      onOk,
      onCancel,
      afterClose: onCancel,
    });
  } else {
    let delay = delayTime - 1;
    const confirmModal = Modal.confirm({
      okText: `(${delay}) ${language.confirm}`,
      okButtonProps: {
        disabled: true || loading,
      },
      cancelText: language.cancel,
      title: language.confirm,
      content: question,
      onOk,
      onCancel,
      afterClose: onCancel,
    });
    const timer = setInterval(() => {
      delay -= 1;
      if (delay === -1) {
        confirmModal.update({
          okText: language.confirm,
          okButtonProps: {
            disabled: false,
          },
        });
        clearInterval(timer);
      } else {
        confirmModal.update({
          okText: `(${delay}) ${language.confirm}`,
        });
      }
    }, 1000);
  }
};

export default useConfirmBox;
