/* eslint-disable import/no-cycle */
import { useContext, useMemo } from 'react';
import { Context as GlobalContext } from 'globalContext';
import { useParams } from 'react-router-dom';

const useCurrentUser = () => {
  const { idBuilding } = useParams();
  const { currentUser, setCurrentUser } = useContext(GlobalContext);

  const handleChangeCurrentUser = (user) => {
    setCurrentUser(user);
  };

  const currentEmployee = useMemo(() => {
    return currentUser?.employees?.find((employee) => employee.unitGroupId === +idBuilding);
  }, [currentUser]);

  return { currentUser, handleChangeCurrentUser, currentEmployee };
};

export default useCurrentUser;
