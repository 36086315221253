/* eslint-disable import/no-cycle */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { getToken, onMessage } from 'firebase/messaging';
import { sendDeviceTokenService } from 'services/fcm';
import notification from 'components/Notification';
import Icon from 'components/Icon';
import { useHistory } from 'react-router-dom';
import { readNotificationService } from 'services/notification';
import { messaging } from '../config/firebase';
import { CH_INSTALLATION } from '../constants';
import useBadge from './useBadge';
import useTheme from './useTheme';

export const NOTICES = (notifyData) => {
  const { unitGroupId, destId } = notifyData;
  return {
    AMENITY_BOOKING_NOTICE: `/buildings/${unitGroupId}/amenities-orders${destId ? `/${destId}` : ''}`,
    UTILITY_BOOKING_NOTICE: `/buildings/${unitGroupId}/amenities-orders${destId ? `/${destId}` : ''}`,
  };
};

export default function useFcm() {
  const { getNotificationBadges, getAmenitiesBookingBadges } = useBadge();
  const history = useHistory();
  const [theme] = useTheme();
  const sendDeviceTokenToServer = async (currentToken) => {
    localStorage.setItem(CH_INSTALLATION, currentToken);
    await sendDeviceTokenService(currentToken, 'browser');
  };

  const handleCallback = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      onMessage(messaging, async ({ data, notification: notificationData }) => {
        const title = notificationData?.title || data?.title;
        const body = notificationData?.body || data?.body;
        const { type, unitGroupId, notificationId } = data;
        const notificationTitle = title;
        await getAmenitiesBookingBadges();
        await getNotificationBadges(unitGroupId);
        notification.open({
          message: notificationTitle,
          description: body,
          onClick: async (event) => {
            event.preventDefault();
            if (unitGroupId) {
              const path = NOTICES(data)?.[type] ?? '';
              if (type !== 'MSG_NOTICE') {
                await readNotificationService(unitGroupId, notificationId);
              }
              if (path) {
                if (type !== 'WEBVIEW_NOTICE') {
                  history.replace(`/buildings/${unitGroupId}/reload`);
                  setTimeout(() => {
                    history.replace(path);
                  });
                }
                if (type === 'WEBVIEW_NOTICE') {
                  window.open(path);
                }
              }
            }
          },
          icon: (
            <Icon
              css={{
                color: theme.primaryColor,
              }}
              name="icon-notification-outlined"
            />
          ),
          style: { cursor: 'pointer' },
        });
      });
    }
  };

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, { vapidKey: process.env.FCM_KEY_PAIR });
      await sendDeviceTokenToServer(token);
    }
  };

  return { requestPermission, handleCallback };
}
