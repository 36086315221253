import moment from 'moment';
import { BILL_TYPE_ADJUST_DOWN, BILL_TYPE_ADJUST_UP } from '../constants/bill';
import { language } from './language';

export default function buildDetailBills({
  createdFor,
  serviceName,
  contractTemplateName,
  totalNumberUse,
  parentId,
  registrationPlate,
  type,
  adjustmentCreatedFor,
}) {
  const createdForConverted = moment(createdFor, 'YYYYMM').format('MM/YYYY');
  const adjustmentCreatedForConverted = moment(adjustmentCreatedFor || createdFor, 'YYYYMM').format('MM/YYYY');
  let note = '';
  let content = '';

  if (type === BILL_TYPE_ADJUST_UP) {
    content = `${
      language.get_more
    } ${serviceName} ${language.label.month.toLowerCase()} ${adjustmentCreatedForConverted} ${
      parentId ? `(${language.label.debt.toLowerCase()})` : ''
    }`;
  } else if (type === BILL_TYPE_ADJUST_DOWN) {
    content = `${
      language.reduce
    } ${serviceName} ${language.label.month.toLowerCase()} ${adjustmentCreatedForConverted}`;
  } else {
    content = `${serviceName}${
      registrationPlate ? ` (${registrationPlate})` : ''
    } ${language.label.month.toLowerCase()} ${createdForConverted} 
     ${parentId ? `(${language.label.debt.toLowerCase()})` : ''}`;
  }

  note = `${language.label.fees}: ${contractTemplateName} - ${totalNumberUse}`;

  return { note, content };
}
