import React, { useState, useEffect } from 'react';
import { find, intersection } from 'lodash';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { language } from '../utils/language';
import useTheme from './useTheme';

const ButtonSelectAllRow = (props) => {
  const {
    isShow,
    isSelectAll,
    totalItemSelected,
    totalItem,
    onSelectAllItemTable,
    textSelectRow,
    textSelectAllRow,
  } = props;
  const [theme] = useTheme();
  let component = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <span
        style={{
          fontWeight: theme.fontWeightBold,
          verticalAlign: 'middle',
          color: theme.blackColor,
        }}
      >
        {totalItem}
      </span>
    </div>
  );
  if (totalItemSelected > 0 || isSelectAll) {
    component = (
      <Typography.Text>
        <span
          style={{
            color: theme.labelColor,
          }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: !isSelectAll
              ? textSelectRow.replace('{totalItemSelected}', totalItemSelected).replace(
                  '{totalItem}',
                  `<span
                style='font-weight: ${theme.fontWeightBold}; color: #000'
              >
                ${totalItem}
              </span>`
                )
              : textSelectRow.replace('{totalItemSelected}', totalItem).replace(
                  '{totalItem}',
                  `<span
          style='font-weight: ${theme.fontWeightBold}; color: #000'
        >
          ${totalItem}
        </span>`
                ),
          }}
        />
        {totalItemSelected > 0 && (
          <Button
            style={{
              padding: '0 0 0 5px',
              height: 0,
              border: 0,
            }}
            type="link"
            onClick={() => onSelectAllItemTable(isShow && !isSelectAll)}
          >
            {totalItem === totalItemSelected
              ? ''
              : (isShow && !isSelectAll && textSelectAllRow.replace('{totalItem}', totalItem)) ||
                language.deselect_all_rows}
          </Button>
        )}
      </Typography.Text>
    );
  }
  return component;
};

export default function useTableCheckbox(props) {
  const { dataRows, rowKey, totalRows } = props;

  const [tableSelection, setDataTableSelection] = useState({
    isSelectAllItem: false,
    isShowQuestionSelectAll: false,
    selectedRowsCache: [], // rows of all page
    selectedRowKeysCache: [], // keys of all page
    selectedRowKeys: [], // keys of page
    byId: {},
  });
  const [leafRelation, setLeafRelation] = useState({});
  const [nodeRelation, setNodeRelation] = useState({});

  const buildStructureData = (data, rowKeyBuild = 'key', byIdDefault = {}) => {
    const result = {};
    const byId = byIdDefault;
    data.forEach((item) => {
      const stack = [];
      stack.push(item);
      byId[item[rowKeyBuild]] = item;
      let node;
      while (stack.length) {
        node = stack.pop();
        if (!node.children) {
          result[node[rowKeyBuild]] = [...stack.map((i) => i[rowKeyBuild])];
        } else {
          const nextNode = find(node.children, (child) => !result[child[rowKeyBuild]]);
          if (nextNode) {
            stack.push(node);
            stack.push(nextNode);
            byId[node[rowKeyBuild]] = node;
            byId[nextNode[rowKeyBuild]] = nextNode;
          } else {
            stack.pop();
          }
        }
      }
    });

    const parent = {};
    Object.keys(result).forEach((key) => {
      result[key].forEach((item) => {
        if (!parent[item]) {
          parent[item] = [];
        }
        parent[item].push(key);
      });
    });
    return [result, parent, byId];
  };

  const isLeaf = (record) => {
    return Boolean(leafRelation[record.key]);
  };

  const convertObjToArray = (obj) =>
    Object.keys(obj)
      // eslint-disable-next-line no-restricted-globals
      .map((key) => (!isNaN(key) ? Number(key) : key))
      .filter(Boolean);

  useEffect(() => {
    const [leafRelationNew, nodeRelationNew, byId] = buildStructureData(dataRows, rowKey, tableSelection.byId);

    const selectedRowKeyObj = {};
    const selectedRowKeysCacheObj = {};
    [...tableSelection.selectedRowKeysCache].forEach((key) => {
      if (!selectedRowKeysCacheObj[key]) {
        selectedRowKeysCacheObj[key] = key;
      }
    });

    dataRows.forEach((dataRow) => {
      if (!tableSelection.isSelectAllItem && selectedRowKeysCacheObj[dataRow[rowKey]]) {
        selectedRowKeyObj[dataRow[rowKey]] = dataRow[rowKey];
        nodeRelationNew?.[dataRow?.[rowKey]]?.forEach((leafKey) => {
          selectedRowKeyObj[leafKey] = leafKey;
        });
      }
      if (tableSelection.isSelectAllItem) {
        selectedRowKeyObj[dataRow[rowKey]] = dataRow[rowKey];
        selectedRowKeysCacheObj[dataRow[rowKey]] = dataRow[rowKey];
        nodeRelationNew?.[dataRow?.[rowKey]]?.forEach((leafKey) => {
          selectedRowKeyObj[leafKey] = leafKey;
          selectedRowKeysCacheObj[leafKey] = leafKey;
        });
      }
    });

    const selectedRowsCache = Object.keys(selectedRowKeysCacheObj)
      .map((key) => byId[key])
      .filter(Boolean);

    setLeafRelation(leafRelationNew);
    setNodeRelation(nodeRelationNew);
    setDataTableSelection({
      ...tableSelection,
      byId,
      selectedRowsCache,
      selectedRowKeysCache: convertObjToArray({ ...selectedRowKeysCacheObj }),
      selectedRowKeys: convertObjToArray({ ...selectedRowKeyObj }),
    });
  }, [dataRows, rowKey, tableSelection.byId, tableSelection.isSelectAllItem]);

  const resetDataTableSelection = () => {
    setDataTableSelection({
      isSelectAllItem: false,
      isShowQuestionSelectAll: false,
      selectedRowsCache: [],
      selectedRowKeysCache: [],
      selectedRowKeys: [],
      byId: tableSelection.byId ?? {},
    });
  };

  const onSelectRow = (record, selected) => {
    const isRecordLeaf = isLeaf(record);
    // convert key obj
    const selectedRowKeyObj = {};
    const selectedRowKeysCacheObj = {};
    [...tableSelection.selectedRowKeys].forEach((key) => {
      if (!selectedRowKeyObj[key]) {
        selectedRowKeyObj[key] = key;
      }
    });
    [...tableSelection.selectedRowKeysCache].forEach((key) => {
      if (!selectedRowKeysCacheObj[key]) {
        selectedRowKeysCacheObj[key] = key;
      }
    });

    if (selected) {
      selectedRowKeyObj[record.key] = record.key;
      selectedRowKeysCacheObj[record.key] = record.key;
      if (isRecordLeaf) {
        leafRelation?.[record?.key]?.forEach((nodeKey) => {
          const leafData = nodeRelation[nodeKey];
          const intersectionData = intersection(convertObjToArray(selectedRowKeyObj), leafData);
          if (leafData.length && intersectionData.length && intersectionData.length === leafData.length) {
            selectedRowKeyObj[nodeKey] = nodeKey;
            selectedRowKeysCacheObj[nodeKey] = nodeKey;
          }
        });
      }

      if (!isRecordLeaf) {
        const leafData = nodeRelation[record.key];
        leafData.forEach((leafKey) => {
          selectedRowKeyObj[leafKey] = leafKey;
          selectedRowKeysCacheObj[leafKey] = leafKey;
        });
      }
    }

    if (!selected) {
      delete selectedRowKeyObj[record.key];
      delete selectedRowKeysCacheObj[record.key];
      if (isRecordLeaf) {
        leafRelation?.[record?.key]?.forEach((nodeKey) => {
          delete selectedRowKeyObj[nodeKey];
          delete selectedRowKeysCacheObj[nodeKey];
        });
      }

      if (!isRecordLeaf) {
        nodeRelation?.[record?.key]?.forEach((leafKey) => {
          delete selectedRowKeyObj[leafKey];
          delete selectedRowKeysCacheObj[leafKey];
        });
      }
    }

    const selectedRowsCache = Object.keys(selectedRowKeysCacheObj)
      .map((key) => tableSelection.byId[key])
      .filter(Boolean);

    setDataTableSelection({
      ...tableSelection,
      isSelectAllItem: false,
      isShowQuestionSelectAll: false,
      selectedRowsCache,
      selectedRowKeysCache: convertObjToArray({ ...selectedRowKeysCacheObj }),
      selectedRowKeys: convertObjToArray({ ...selectedRowKeyObj }),
    });
  };

  const onSelectAllRowOfPage = (selected, selectedRows) => {
    const selectedRowKeys = selectedRows.filter(Boolean).map((selectedRow) => selectedRow[rowKey]);
    const selectedRowKeysCacheObj = {};
    [...tableSelection.selectedRowKeysCache].forEach((key) => {
      if (!selectedRowKeysCacheObj[key]) {
        selectedRowKeysCacheObj[key] = key;
      }
    });

    if (selected) {
      selectedRowKeys.forEach((key) => {
        selectedRowKeysCacheObj[key] = key;
      });
    }

    if (!selected) {
      tableSelection.selectedRowKeys.forEach((key) => {
        delete selectedRowKeysCacheObj[key];
      });
    }

    const selectedRowsCache = Object.keys(selectedRowKeysCacheObj)
      .map((key) => tableSelection.byId[key])
      .filter(Boolean);

    setDataTableSelection({
      ...tableSelection,
      isShowQuestionSelectAll: selected,
      isSelectAllItem: false,
      selectedRowsCache,
      selectedRowKeysCache: convertObjToArray({ ...selectedRowKeysCacheObj }),
      selectedRowKeys,
    });
  };

  const onSelectAllRow = (selected) => {
    if (!selected) {
      setDataTableSelection({
        ...tableSelection,
        isSelectAllItem: false,
        isShowQuestionSelectAll: false,
        selectedRowsCache: [],
        selectedRowKeysCache: [],
        selectedRowKeys: [],
      });
    } else {
      setDataTableSelection({
        ...tableSelection,
        isSelectAllItem: true,
      });
    }
  };

  const selectAllRowComponent = (propsSelectAll = {}) => {
    const {
      totalItemSelected = tableSelection.selectedRowKeysCache.length,
      textSelectRow = language.you_selected_rows,
      textSelectAllRow = language.select_all_rows,
    } = propsSelectAll;
    return (
      <ButtonSelectAllRow
        isShow={tableSelection.isShowQuestionSelectAll}
        isSelectAll={tableSelection.isSelectAllItem}
        totalItemSelected={totalItemSelected}
        totalItem={totalRows}
        textSelectRow={textSelectRow}
        textSelectAllRow={textSelectAllRow}
        onSelectAllItemTable={onSelectAllRow}
      />
    );
  };

  return [tableSelection, resetDataTableSelection, onSelectRow, onSelectAllRowOfPage, selectAllRowComponent];
}
