import { API_TYPE_CALL_API, API_TYPE_DOWNLOAD_FILE, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const SERVICE = `${SERVER}/amenities`;
const SERVICE_GROUP_GETS = `${SERVICE}/group/list`;
const SERVICE_GROUP_GET = (id) => `${SERVICE}/group/${id}`;
const SERVICE_GROUP_ADD = `${SERVICE}/group`;
const SERVICE_GROUP_UPDATE = (id) => `${SERVICE}/group/${id}`;
const SERVICE_GROUP_GET_PRIORITY_IN_APP_SELECTED = `${SERVICE}/group/list-priority`;
const SERVICE_GETS = `${SERVICE}/list`;
const SERVICE_GET = (id) => `${SERVICE}/${id}`;
const SERVICE_GET_PRIORITY_IN_APP_SELECTED = `${SERVICE}/list-priority`;
const SERVICE_ADD = SERVICE;
const SERVICE_UPDATE = (id) => `${SERVICE}/${id}`;
const SERVICE_ACTIVE = (id) => `${SERVICE}/${id}/active`;
const SERVICE_INACTIVE = (id) => `${SERVICE}/${id}/inactive`;
const SERVICE_ORDER_GETS = `${SERVICE}/booking/list`;
const AMENITY_MERCHANT_ORDERS_GETS = `${SERVICE}/pms/merchant/booking/list`;
const AMENITY_MERCHANT_ORDERS_EXPORT = `${SERVICE}/pms/merchant/booking/export`;
const AMENITY_MERCHANT_ORDERS_GET = (id) => `${SERVICE}/pms/merchant/booking/${id}`;
const SERVICE_ORDER_EXPORT = `${SERVICE}/booking/export`;
const SERVICE_ORDER_APPROVE = `${SERVICE}/booking/approve`;
const SERVICE_ORDER_REJECT = `${SERVICE}/booking/reject`;
const SERVICE_ORDER_CANCEL = (id) => `${SERVICE}/booking/${id}/cancel`;
const SERVICE_ORDER_UPDATE = (id) => `${SERVICE}/booking/${id}`;
const SERVICE_ORDER_GET = (id) => `${SERVICE}/booking/${id}`;
const SERVICE_ORDER_PAYMENT = (id) => `${SERVICE}/booking/${id}/pay-manual`;
const SERVICE_ORDER_REFUND = (id) => `${SERVICE}/booking/${id}/refund-manual`;
const SERVICE_ORDER_REFUND_DEPOSITS = (id) => `${SERVICE}/booking/${id}/refund-manual`;
const SERVICE_COUNT_BADGES = `${SERVICE}/count-badges`;
const SERVICE_ORDER_CHECK_IN = (id) => `${SERVICE}/booking/${id}/check-in`;

export async function getListAmenitiesGroupService({ filter, page, limit }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_GROUP_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
      },
    },
  });
  return response;
}

export async function getAmenitiesGroupService(idAmenitiesGroup) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_GROUP_GET(idAmenitiesGroup),
      method: METHOD.GET,
    },
  });
  return response;
}

export async function addAmenitiesGroupService(amenitiesGroup) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_GROUP_ADD,
      method: METHOD.POST,
      body: {
        ...amenitiesGroup,
      },
    },
  });
  return response;
}

export async function updateAmenitiesGroupService(idAmenitiesGroup, amenitiesGroup) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_GROUP_UPDATE(idAmenitiesGroup),
      method: METHOD.PUT,
      body: {
        ...amenitiesGroup,
      },
    },
  });
  return response;
}

export async function getsPriorityInAppSelectedOfAmenitiesGroupService(idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_GROUP_GET_PRIORITY_IN_APP_SELECTED,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getsAmenitiesService({ params, headers }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_GETS,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function getAmenitiesService({ params, headers }) {
  const { id } = params;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_GET(id),
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function getsPriorityInAppSelectedService({ params, headers }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_GET_PRIORITY_IN_APP_SELECTED,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function addAmenitiesService(amenitiesData) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ADD,
      method: METHOD.POST,
      body: {
        ...amenitiesData,
      },
    },
  });
  return response;
}

export async function updateAmenitiesService(idAmenities, amenitiesData) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_UPDATE(idAmenities),
      method: METHOD.PUT,
      body: {
        ...amenitiesData,
      },
    },
  });
  return response;
}

export async function activeAmenitiesService({ body, headers }) {
  const { id } = body;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ACTIVE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function inactiveAmenitiesService({ body, headers }) {
  const { id } = body;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_INACTIVE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function getsAmenitiesOrderService({ filter, limit, page }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ORDER_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          limit,
          page,
        },
      },
    },
  });
  return response;
}

export async function getsAmenityMerchantOrderService({ filter, limit, page, idBuilding }) {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const params = { ...filter, limit, page };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITY_MERCHANT_ORDERS_GETS,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function exportAmenityMerchantOrderService(filter, idBuilding) {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const params = { ...filter };
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: AMENITY_MERCHANT_ORDERS_EXPORT,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function getAmenityMerchantOrderService(id, idBuilding) {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITY_MERCHANT_ORDERS_GET(id),
      method: METHOD.GET,
      options: { headers },
    },
  });
  return response;
}

export async function getAmenitiesOrderService({ params, headers }) {
  const { id } = params;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ORDER_GET(id),
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function exportAmenitiesOrderService({ params }) {
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: SERVICE_ORDER_EXPORT,
      method: METHOD.GET,
      options: {
        params,
      },
    },
  });
  return response;
}

export async function approveAmenitiesOrdersService({ body, headers }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ORDER_APPROVE,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function rejectAmenitiesOrdersService({ body, headers }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ORDER_REJECT,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function cancelAmenitiesOrdersService({ id, body, idBuilding }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ORDER_CANCEL(id),
      method: METHOD.PUT,
      body,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function updateAmenitiesOrdersService({ id, body }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ORDER_UPDATE(id),
      method: METHOD.PUT,
      body,
    },
  });
  return response;
}

export async function paymentAmenitiesOrderService({ body, headers }) {
  const { id } = body;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ORDER_PAYMENT(id),
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function refundAmenitiesOrderService({ body, headers }) {
  const { id } = body;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ORDER_REFUND(id),
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function refundDepositAmenitiesOrderService({ body, headers }) {
  const { id } = body;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ORDER_REFUND_DEPOSITS(id),
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export const countBadgesAmenitiesBookingService = async (idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_COUNT_BADGES,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export async function checkInAmenitiesOrdersService(id) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ORDER_CHECK_IN(id),
      method: METHOD.PUT,
    },
  });
  return response;
}
