/** @jsx jsx */
import 'moment/locale/eu';
import 'moment/locale/vi';
import { hot } from 'react-hot-loader';
import { Switch, Route } from 'react-router-dom';
import { jsx } from '@emotion/react';
import React, { Suspense, useEffect } from 'react';
import Loading from 'components/Loading';
import { useFcm } from 'shared/helpers';

const Print = React.lazy(() => import('components/Print'));

const NormalLogin = React.lazy(() => import('containers/Auth/Login'));

const Register = React.lazy(() => import('containers/Auth/Register'));

const ForgotPassword = React.lazy(() => import('containers/Auth/ForgotPassword'));

const NewPassword = React.lazy(() => import('containers/Auth/NewPassword'));

const ApplicationComponent = React.lazy(() => import('containers/Application'));

function Routes() {
  const { requestPermission, handleCallback } = useFcm();

  useEffect(() => {
    (async () => {
      await requestPermission();
      await handleCallback();
    })();
  }, []);

  return (
    <Suspense fallback={<Loading isLoading />}>
      <Switch>
        <Route path="/print" component={Print} />
        <Route path="/login" component={NormalLogin} />
        <Route path="/register" component={Register} />
        <Route path="/password/forgot" component={ForgotPassword} />
        <Route path="/password/set-new-password" component={NewPassword} />
        <Route path="/application" component={ApplicationComponent} />
        <Route path="/" component={ApplicationComponent} />
      </Switch>
    </Suspense>
  );
}

export default hot(module)(Routes);
