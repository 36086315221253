import { SERVICE_ORDER_DETAIL } from './amenities-detail.types';

export const getOrder = ({ orderId, buildingId }) => ({
  type: SERVICE_ORDER_DETAIL.GET.REQUEST,
  payload: {
    orderId,
    buildingId,
  },
});

export const approveOrder = ({ orderId, buildingId }) => ({
  type: SERVICE_ORDER_DETAIL.APPROVE.REQUEST,
  payload: {
    orderId,
    buildingId,
  },
});

export const rejectOrder = ({ orderId, buildingId, reason }) => ({
  type: SERVICE_ORDER_DETAIL.REJECT.REQUEST,
  payload: {
    reason,
    orderId,
    buildingId,
  },
});

export const cancelOrder = ({ orderId, buildingId, reason }) => ({
  type: SERVICE_ORDER_DETAIL.CANCEL.REQUEST,
  payload: {
    reason,
    orderId,
    buildingId,
  },
});

export const paymentOrder = ({ orderId, buildingId, params }) => ({
  type: SERVICE_ORDER_DETAIL.PAYMENT.REQUEST,
  payload: {
    orderId,
    buildingId,
    params,
  },
});

export const paymentOrderRefresh = () => ({
  type: SERVICE_ORDER_DETAIL.PAYMENT.REFRESH,
  payload: {},
});

export const refundOrder = ({ orderId, buildingId, params }) => ({
  type: SERVICE_ORDER_DETAIL.REFUND.REQUEST,
  payload: {
    orderId,
    buildingId,
    params,
  },
});

export const refundOrderRefresh = () => ({
  type: SERVICE_ORDER_DETAIL.REFUND.REFRESH,
  payload: {},
});

export const refundDepositOrder = ({ orderId, buildingId, params }) => ({
  type: SERVICE_ORDER_DETAIL.REFUND_DEPOSIT.REQUEST,
  payload: {
    orderId,
    buildingId,
    params,
  },
});

export const refundDepositOrderRefresh = () => ({
  type: SERVICE_ORDER_DETAIL.REFUND_DEPOSIT.REFRESH,
  payload: {},
});

export const previewReceipt = ({ idBuilding, idReceipt }) => ({
  type: SERVICE_ORDER_DETAIL.PREVIEW_RECEIPT.REQUEST,
  payload: {
    idBuilding,
    idReceipt,
  },
});

export const previewPaySlip = ({ unitGroupId, paySlipId }) => ({
  type: SERVICE_ORDER_DETAIL.PREVIEW_PAYSLIP.REQUEST,
  payload: {
    unitGroupId,
    paySlipId,
  },
});
