import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();
  const history = useHistory();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const removeQuery = (key) => {
    if (queryParams.has(key)) {
      queryParams.delete(key);
      history.replace({
        search: queryParams.toString(),
      });
    }
  };
  return { queryParams, removeQuery };
};

export default useQuery;
