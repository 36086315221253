import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import { useForm } from 'shared/helpers';

const Context = React.createContext({
  currentUser: undefined,
  setCurrentUser: () => {},
  currentBuilding: undefined,
  setCurrentBuilding: () => {},
  funds: [],
  setFunds: () => {},
  labels: [],
  setLabels: () => {},
  labelTrees: [],
  setLabelTrees: () => {},
  authorities: [],
  setAuthorities: () => {},
  visibleFilterBox: false,
  setVisibleFilterBox: () => {},
  apartmentTypes: [],
  setApartmentTypes: () => {},
  floors: [],
  setFloors: () => {},
  titlePage: '',
  setTitlePage: () => {},
  visibleSearchBox: false,
  setVisibleSearchBox: () => {},
  noticeSearchBox: 0,
  setNoticeSearchBox: () => {},
  layoutScrollRef: undefined,
  buildings: [],
  setBuildings: () => {},
  badges: {
    request: 0,
  },
  setBadges: () => {},
  drawerNotificationHistory: {
    isShow: false,
    data: {},
  },
  showDrawerNotificationHistory: () => {},
  hideDrawerNotificationHistory: () => {},
});
const layoutScrollRef = React.createRef(null);

const GlobalContext = (props) => {
  const { children } = props;
  const [buildings, setBuildings] = useState([]);
  const [apartmentTypes, setApartmentTypes] = useState([]);
  const [floors, setFloors] = useState([]);
  const [currentBuilding, setCurrentBuilding] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [funds, setFunds] = useState([]);
  const [labels, setLabels] = useState([]);
  const [labelTrees, setLabelTrees] = useState([]);
  const [authorities, setAuthorities] = useState([]);
  const [titlePage, setTitlePage] = useState('');
  const [visibleFilterBox, setVisibleFilterBox] = useState(false);
  const [visibleSearchBox, setVisibleSearchBox] = useState(false);
  const [noticeSearchBox, setNoticeSearchBox] = useState(0);
  const [badges, setBadges] = useState({
    request: 0,
  });
  const [drawerNotificationHistory, showDrawerNotificationHistory, hideDrawerNotificationHistory] = useForm();

  const ctx = {
    currentBuilding,
    setCurrentBuilding,
    currentUser,
    setCurrentUser,
    funds,
    setFunds,
    labels,
    setLabels,
    labelTrees,
    setLabelTrees,
    authorities,
    setAuthorities,
    visibleFilterBox,
    setVisibleFilterBox,
    apartmentTypes,
    setApartmentTypes,
    floors,
    setFloors,
    titlePage,
    setTitlePage,
    visibleSearchBox,
    setVisibleSearchBox,
    noticeSearchBox,
    setNoticeSearchBox,
    layoutScrollRef,
    buildings,
    setBuildings,
    badges,
    setBadges,
    drawerNotificationHistory,
    showDrawerNotificationHistory,
    hideDrawerNotificationHistory,
  };
  return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

export { Context };

GlobalContext.propTypes = {
  children: PropTypes.node,
};

GlobalContext.defaultProps = {
  children: <div />,
};

export default GlobalContext;
