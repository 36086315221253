/* eslint-disable import/no-cycle */
import { useContext } from 'react';
import { Context } from 'globalContext';
import { countBadgesAmenitiesBookingService } from 'services/amenities';
import { countBadgesNotificationService } from 'services/notification';

const useBadge = () => {
  const { badges, setBadges } = useContext(Context);

  const getBadges = async (idBuilding) => {
    const [amenitiesBadges, notificationBadges] = await Promise.all([
      countBadgesAmenitiesBookingService(idBuilding),
      countBadgesNotificationService(idBuilding),
    ]);

    setBadges({
      amenities: amenitiesBadges?.data ?? 0,
      notification: notificationBadges?.data?.badge ?? 0,
    });
  };

  const getNotificationBadges = async (idBuilding) => {
    const notificationBadges = await countBadgesNotificationService(idBuilding);
    setBadges({
      ...badges,
      notification: notificationBadges?.data?.badge ?? 0,
    });
    return notificationBadges?.data?.badge ?? 0;
  };

  const getAmenitiesBookingBadges = async (idBuilding) => {
    const amenitiesBookingBadges = await countBadgesAmenitiesBookingService(idBuilding);
    setBadges({
      ...badges,
      amenities: amenitiesBookingBadges?.data ?? 0,
    });
  };

  return {
    badges,
    setBadges,
    getBadges,
    getNotificationBadges,
    getAmenitiesBookingBadges,
  };
};

export default useBadge;
